/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ConversionProcessMetricDto } from '../models';
// @ts-ignore
import type { GetMemberReferralsSummaryMetricZoneIdParameter } from '../models';
// @ts-ignore
import type { MemberReferralsSummaryMetricDto } from '../models';
// @ts-ignore
import type { MemberSegmentBreakdownMetricDto } from '../models';
// @ts-ignore
import type { MemberUnsubscribesMetricDto } from '../models';
// @ts-ignore
import type { MembersAgeBreakdownMetricDto } from '../models';
// @ts-ignore
import type { MembersAgeGenderBreakdownMetricDto } from '../models';
// @ts-ignore
import type { MembersGenderBreakdownMetricDto } from '../models';
// @ts-ignore
import type { MembersJoiningTypeBreakdownMetricDto } from '../models';
// @ts-ignore
import type { MembersJoiningTypeDataPointsMetricDto } from '../models';
// @ts-ignore
import type { MembersPointsAmountMetricDto } from '../models';
// @ts-ignore
import type { ProblemDetail } from '../models';
// @ts-ignore
import type { ReferralProgramReferralsMetricDto } from '../models';
// @ts-ignore
import type { ReferralProgramTopReferrersMetricDto } from '../models';
// @ts-ignore
import type { RelativeMetricDto } from '../models';
// @ts-ignore
import type { TierBreakdownMetricDto } from '../models';
// @ts-ignore
import type { TotalConsumersMetricDto } from '../models';
/**
 * MemberServiceMetricsInternalControllerApi - axios parameter creator
 * @export
 */
export const MemberServiceMetricsInternalControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversionProcessMetric: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getConversionProcessMetric', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/members-points-conversion-process`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberAgeBreakdownMetric: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMemberAgeBreakdownMetric', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/members-age-breakdown`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberAgeGenderBreakdownMetric: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMemberAgeGenderBreakdownMetric', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/members-age-gender-breakdown`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberGenderBreakdownMetric: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMemberGenderBreakdownMetric', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/members-gender-breakdown`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberJoiningTypeBreakdownMetric: async (businessEntityId: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMemberJoiningTypeBreakdownMetric', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/members-joining-type-breakdown`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {GetMemberReferralsSummaryMetricZoneIdParameter} zoneId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberJoiningTypeDataPointsMetric: async (businessEntityId: string, zoneId: GetMemberReferralsSummaryMetricZoneIdParameter, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMemberJoiningTypeDataPointsMetric', 'businessEntityId', businessEntityId)
            // verify required parameter 'zoneId' is not null or undefined
            assertParamExists('getMemberJoiningTypeDataPointsMetric', 'zoneId', zoneId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/members-joining-type-data-points`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (zoneId !== undefined) {
                for (const [key, value] of Object.entries(zoneId)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {GetMemberReferralsSummaryMetricZoneIdParameter} zoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberReferralsSummaryMetric: async (businessEntityId: string, zoneId: GetMemberReferralsSummaryMetricZoneIdParameter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMemberReferralsSummaryMetric', 'businessEntityId', businessEntityId)
            // verify required parameter 'zoneId' is not null or undefined
            assertParamExists('getMemberReferralsSummaryMetric', 'zoneId', zoneId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/referrals-summary-data-points`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (zoneId !== undefined) {
                for (const [key, value] of Object.entries(zoneId)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberSegmentBreakDownMetric: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMemberSegmentBreakDownMetric', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/members-segment-breakdown`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberTierBreakdownMetric: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMemberTierBreakdownMetric', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/members-tier-breakdown`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {Array<string>} pointsSymbols 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersPointsAmountMetric: async (businessEntityId: string, pointsSymbols: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMembersPointsAmountMetric', 'businessEntityId', businessEntityId)
            // verify required parameter 'pointsSymbols' is not null or undefined
            assertParamExists('getMembersPointsAmountMetric', 'pointsSymbols', pointsSymbols)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/members-points-amount`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pointsSymbols) {
                localVarQueryParameter['pointsSymbols'] = Array.from(pointsSymbols);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgramReferralsMetric: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getReferralProgramReferralsMetric', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/referral-program-referrals`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgramTopReferrersMetric: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getReferralProgramTopReferrersMetric', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/referral-program-top-referrers`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalConsumersMetric: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/metrics/total-consumers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {boolean} [ghostAccounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalMembersMetric: async (businessEntityId: string, ghostAccounts?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getTotalMembersMetric', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/total-members`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ghostAccounts !== undefined) {
                localVarQueryParameter['ghostAccounts'] = ghostAccounts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnsubscribedMembersMetric: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getUnsubscribedMembersMetric', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/metrics/business-entities/{businessEntityId}/unsubscribed-members`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberServiceMetricsInternalControllerApi - functional programming interface
 * @export
 */
export const MemberServiceMetricsInternalControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberServiceMetricsInternalControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversionProcessMetric(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversionProcessMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversionProcessMetric(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getConversionProcessMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberAgeBreakdownMetric(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembersAgeBreakdownMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberAgeBreakdownMetric(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getMemberAgeBreakdownMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberAgeGenderBreakdownMetric(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembersAgeGenderBreakdownMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberAgeGenderBreakdownMetric(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getMemberAgeGenderBreakdownMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberGenderBreakdownMetric(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembersGenderBreakdownMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberGenderBreakdownMetric(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getMemberGenderBreakdownMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberJoiningTypeBreakdownMetric(businessEntityId: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembersJoiningTypeBreakdownMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberJoiningTypeBreakdownMetric(businessEntityId, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getMemberJoiningTypeBreakdownMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {GetMemberReferralsSummaryMetricZoneIdParameter} zoneId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberJoiningTypeDataPointsMetric(businessEntityId: string, zoneId: GetMemberReferralsSummaryMetricZoneIdParameter, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembersJoiningTypeDataPointsMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberJoiningTypeDataPointsMetric(businessEntityId, zoneId, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getMemberJoiningTypeDataPointsMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {GetMemberReferralsSummaryMetricZoneIdParameter} zoneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberReferralsSummaryMetric(businessEntityId: string, zoneId: GetMemberReferralsSummaryMetricZoneIdParameter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberReferralsSummaryMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberReferralsSummaryMetric(businessEntityId, zoneId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getMemberReferralsSummaryMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberSegmentBreakDownMetric(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberSegmentBreakdownMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberSegmentBreakDownMetric(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getMemberSegmentBreakDownMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberTierBreakdownMetric(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TierBreakdownMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberTierBreakdownMetric(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getMemberTierBreakdownMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {Array<string>} pointsSymbols 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembersPointsAmountMetric(businessEntityId: string, pointsSymbols: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembersPointsAmountMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembersPointsAmountMetric(businessEntityId, pointsSymbols, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getMembersPointsAmountMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralProgramReferralsMetric(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralProgramReferralsMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralProgramReferralsMetric(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getReferralProgramReferralsMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralProgramTopReferrersMetric(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralProgramTopReferrersMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralProgramTopReferrersMetric(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getReferralProgramTopReferrersMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalConsumersMetric(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalConsumersMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalConsumersMetric(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getTotalConsumersMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {boolean} [ghostAccounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalMembersMetric(businessEntityId: string, ghostAccounts?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelativeMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalMembersMetric(businessEntityId, ghostAccounts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getTotalMembersMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnsubscribedMembersMetric(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberUnsubscribesMetricDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnsubscribedMembersMetric(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceMetricsInternalControllerApi.getUnsubscribedMembersMetric']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MemberServiceMetricsInternalControllerApi - factory interface
 * @export
 */
export const MemberServiceMetricsInternalControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberServiceMetricsInternalControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetConversionProcessMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversionProcessMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetConversionProcessMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<ConversionProcessMetricDto> {
            return localVarFp.getConversionProcessMetric(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetMemberAgeBreakdownMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberAgeBreakdownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberAgeBreakdownMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<MembersAgeBreakdownMetricDto> {
            return localVarFp.getMemberAgeBreakdownMetric(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetMemberAgeGenderBreakdownMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberAgeGenderBreakdownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberAgeGenderBreakdownMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<MembersAgeGenderBreakdownMetricDto> {
            return localVarFp.getMemberAgeGenderBreakdownMetric(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetMemberGenderBreakdownMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberGenderBreakdownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberGenderBreakdownMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<MembersGenderBreakdownMetricDto> {
            return localVarFp.getMemberGenderBreakdownMetric(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeBreakdownMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberJoiningTypeBreakdownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeBreakdownMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<MembersJoiningTypeBreakdownMetricDto> {
            return localVarFp.getMemberJoiningTypeBreakdownMetric(requestParameters.businessEntityId, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeDataPointsMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberJoiningTypeDataPointsMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeDataPointsMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<MembersJoiningTypeDataPointsMetricDto> {
            return localVarFp.getMemberJoiningTypeDataPointsMetric(requestParameters.businessEntityId, requestParameters.zoneId, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetMemberReferralsSummaryMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberReferralsSummaryMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberReferralsSummaryMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberReferralsSummaryMetricDto> {
            return localVarFp.getMemberReferralsSummaryMetric(requestParameters.businessEntityId, requestParameters.zoneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetMemberSegmentBreakDownMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberSegmentBreakDownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberSegmentBreakDownMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberSegmentBreakdownMetricDto> {
            return localVarFp.getMemberSegmentBreakDownMetric(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetMemberTierBreakdownMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberTierBreakdownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberTierBreakdownMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<TierBreakdownMetricDto> {
            return localVarFp.getMemberTierBreakdownMetric(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetMembersPointsAmountMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersPointsAmountMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMembersPointsAmountMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<MembersPointsAmountMetricDto> {
            return localVarFp.getMembersPointsAmountMetric(requestParameters.businessEntityId, requestParameters.pointsSymbols, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetReferralProgramReferralsMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgramReferralsMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetReferralProgramReferralsMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReferralProgramReferralsMetricDto> {
            return localVarFp.getReferralProgramReferralsMetric(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetReferralProgramTopReferrersMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgramTopReferrersMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetReferralProgramTopReferrersMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReferralProgramTopReferrersMetricDto> {
            return localVarFp.getReferralProgramTopReferrersMetric(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalConsumersMetric(options?: RawAxiosRequestConfig): AxiosPromise<TotalConsumersMetricDto> {
            return localVarFp.getTotalConsumersMetric(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetTotalMembersMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalMembersMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetTotalMembersMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<RelativeMetricDto> {
            return localVarFp.getTotalMembersMetric(requestParameters.businessEntityId, requestParameters.ghostAccounts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberServiceMetricsInternalControllerApiGetUnsubscribedMembersMetricRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnsubscribedMembersMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetUnsubscribedMembersMetricRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberUnsubscribesMetricDto> {
            return localVarFp.getUnsubscribedMembersMetric(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getConversionProcessMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetConversionProcessMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetConversionProcessMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetConversionProcessMetric
     */
    readonly businessEntityId: string
}

/**
 * Request parameters for getMemberAgeBreakdownMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetMemberAgeBreakdownMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetMemberAgeBreakdownMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberAgeBreakdownMetric
     */
    readonly businessEntityId: string
}

/**
 * Request parameters for getMemberAgeGenderBreakdownMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetMemberAgeGenderBreakdownMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetMemberAgeGenderBreakdownMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberAgeGenderBreakdownMetric
     */
    readonly businessEntityId: string
}

/**
 * Request parameters for getMemberGenderBreakdownMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetMemberGenderBreakdownMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetMemberGenderBreakdownMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberGenderBreakdownMetric
     */
    readonly businessEntityId: string
}

/**
 * Request parameters for getMemberJoiningTypeBreakdownMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeBreakdownMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeBreakdownMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeBreakdownMetric
     */
    readonly businessEntityId: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeBreakdownMetric
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeBreakdownMetric
     */
    readonly endDate?: string
}

/**
 * Request parameters for getMemberJoiningTypeDataPointsMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeDataPointsMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeDataPointsMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeDataPointsMetric
     */
    readonly businessEntityId: string

    /**
     * 
     * @type {GetMemberReferralsSummaryMetricZoneIdParameter}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeDataPointsMetric
     */
    readonly zoneId: GetMemberReferralsSummaryMetricZoneIdParameter

    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeDataPointsMetric
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeDataPointsMetric
     */
    readonly endDate?: string
}

/**
 * Request parameters for getMemberReferralsSummaryMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetMemberReferralsSummaryMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetMemberReferralsSummaryMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberReferralsSummaryMetric
     */
    readonly businessEntityId: string

    /**
     * 
     * @type {GetMemberReferralsSummaryMetricZoneIdParameter}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberReferralsSummaryMetric
     */
    readonly zoneId: GetMemberReferralsSummaryMetricZoneIdParameter
}

/**
 * Request parameters for getMemberSegmentBreakDownMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetMemberSegmentBreakDownMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetMemberSegmentBreakDownMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberSegmentBreakDownMetric
     */
    readonly businessEntityId: string
}

/**
 * Request parameters for getMemberTierBreakdownMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetMemberTierBreakdownMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetMemberTierBreakdownMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMemberTierBreakdownMetric
     */
    readonly businessEntityId: string
}

/**
 * Request parameters for getMembersPointsAmountMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetMembersPointsAmountMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetMembersPointsAmountMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetMembersPointsAmountMetric
     */
    readonly businessEntityId: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberServiceMetricsInternalControllerApiGetMembersPointsAmountMetric
     */
    readonly pointsSymbols: Array<string>
}

/**
 * Request parameters for getReferralProgramReferralsMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetReferralProgramReferralsMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetReferralProgramReferralsMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetReferralProgramReferralsMetric
     */
    readonly businessEntityId: string
}

/**
 * Request parameters for getReferralProgramTopReferrersMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetReferralProgramTopReferrersMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetReferralProgramTopReferrersMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetReferralProgramTopReferrersMetric
     */
    readonly businessEntityId: string
}

/**
 * Request parameters for getTotalMembersMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetTotalMembersMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetTotalMembersMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetTotalMembersMetric
     */
    readonly businessEntityId: string

    /**
     * 
     * @type {boolean}
     * @memberof MemberServiceMetricsInternalControllerApiGetTotalMembersMetric
     */
    readonly ghostAccounts?: boolean
}

/**
 * Request parameters for getUnsubscribedMembersMetric operation in MemberServiceMetricsInternalControllerApi.
 * @export
 * @interface MemberServiceMetricsInternalControllerApiGetUnsubscribedMembersMetricRequest
 */
export interface MemberServiceMetricsInternalControllerApiGetUnsubscribedMembersMetricRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceMetricsInternalControllerApiGetUnsubscribedMembersMetric
     */
    readonly businessEntityId: string
}

/**
 * MemberServiceMetricsInternalControllerApi - object-oriented interface
 * @export
 * @class MemberServiceMetricsInternalControllerApi
 * @extends {BaseAPI}
 */
export class MemberServiceMetricsInternalControllerApi extends BaseAPI {
    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetConversionProcessMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getConversionProcessMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetConversionProcessMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getConversionProcessMetric(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetMemberAgeBreakdownMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getMemberAgeBreakdownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberAgeBreakdownMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getMemberAgeBreakdownMetric(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetMemberAgeGenderBreakdownMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getMemberAgeGenderBreakdownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberAgeGenderBreakdownMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getMemberAgeGenderBreakdownMetric(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetMemberGenderBreakdownMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getMemberGenderBreakdownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberGenderBreakdownMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getMemberGenderBreakdownMetric(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeBreakdownMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getMemberJoiningTypeBreakdownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeBreakdownMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getMemberJoiningTypeBreakdownMetric(requestParameters.businessEntityId, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeDataPointsMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getMemberJoiningTypeDataPointsMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberJoiningTypeDataPointsMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getMemberJoiningTypeDataPointsMetric(requestParameters.businessEntityId, requestParameters.zoneId, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetMemberReferralsSummaryMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getMemberReferralsSummaryMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberReferralsSummaryMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getMemberReferralsSummaryMetric(requestParameters.businessEntityId, requestParameters.zoneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetMemberSegmentBreakDownMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getMemberSegmentBreakDownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberSegmentBreakDownMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getMemberSegmentBreakDownMetric(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetMemberTierBreakdownMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getMemberTierBreakdownMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMemberTierBreakdownMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getMemberTierBreakdownMetric(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetMembersPointsAmountMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getMembersPointsAmountMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetMembersPointsAmountMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getMembersPointsAmountMetric(requestParameters.businessEntityId, requestParameters.pointsSymbols, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetReferralProgramReferralsMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getReferralProgramReferralsMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetReferralProgramReferralsMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getReferralProgramReferralsMetric(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetReferralProgramTopReferrersMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getReferralProgramTopReferrersMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetReferralProgramTopReferrersMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getReferralProgramTopReferrersMetric(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getTotalConsumersMetric(options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getTotalConsumersMetric(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetTotalMembersMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getTotalMembersMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetTotalMembersMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getTotalMembersMetric(requestParameters.businessEntityId, requestParameters.ghostAccounts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberServiceMetricsInternalControllerApiGetUnsubscribedMembersMetricRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceMetricsInternalControllerApi
     */
    public getUnsubscribedMembersMetric(requestParameters: MemberServiceMetricsInternalControllerApiGetUnsubscribedMembersMetricRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceMetricsInternalControllerApiFp(this.configuration).getUnsubscribedMembersMetric(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }
}

