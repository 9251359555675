/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { PagedReferrerWithRefereeReportInternalDto } from '../models';
// @ts-ignore
import type { ProblemDetail } from '../models';
/**
 * MemberServiceReportsInternalControllerApi - axios parameter creator
 * @export
 */
export const MemberServiceReportsInternalControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessEntityId 
         * @param {string} [referredWhenFrom] 
         * @param {string} [referredWhenTo] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberReferralReport: async (businessEntityId: string, referredWhenFrom?: string, referredWhenTo?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMemberReferralReport', 'businessEntityId', businessEntityId)
            const localVarPath = `/internal/reports/member-referral`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (businessEntityId !== undefined) {
                localVarQueryParameter['businessEntityId'] = businessEntityId;
            }

            if (referredWhenFrom !== undefined) {
                localVarQueryParameter['referredWhenFrom'] = (referredWhenFrom as any instanceof Date) ?
                    (referredWhenFrom as any).toISOString() :
                    referredWhenFrom;
            }

            if (referredWhenTo !== undefined) {
                localVarQueryParameter['referredWhenTo'] = (referredWhenTo as any instanceof Date) ?
                    (referredWhenTo as any).toISOString() :
                    referredWhenTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberServiceReportsInternalControllerApi - functional programming interface
 * @export
 */
export const MemberServiceReportsInternalControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberServiceReportsInternalControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessEntityId 
         * @param {string} [referredWhenFrom] 
         * @param {string} [referredWhenTo] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberReferralReport(businessEntityId: string, referredWhenFrom?: string, referredWhenTo?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedReferrerWithRefereeReportInternalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemberReferralReport(businessEntityId, referredWhenFrom, referredWhenTo, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MemberServiceReportsInternalControllerApi.getMemberReferralReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MemberServiceReportsInternalControllerApi - factory interface
 * @export
 */
export const MemberServiceReportsInternalControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberServiceReportsInternalControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MemberServiceReportsInternalControllerApiGetMemberReferralReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberReferralReport(requestParameters: MemberServiceReportsInternalControllerApiGetMemberReferralReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<PagedReferrerWithRefereeReportInternalDto> {
            return localVarFp.getMemberReferralReport(requestParameters.businessEntityId, requestParameters.referredWhenFrom, requestParameters.referredWhenTo, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMemberReferralReport operation in MemberServiceReportsInternalControllerApi.
 * @export
 * @interface MemberServiceReportsInternalControllerApiGetMemberReferralReportRequest
 */
export interface MemberServiceReportsInternalControllerApiGetMemberReferralReportRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceReportsInternalControllerApiGetMemberReferralReport
     */
    readonly businessEntityId: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceReportsInternalControllerApiGetMemberReferralReport
     */
    readonly referredWhenFrom?: string

    /**
     * 
     * @type {string}
     * @memberof MemberServiceReportsInternalControllerApiGetMemberReferralReport
     */
    readonly referredWhenTo?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof MemberServiceReportsInternalControllerApiGetMemberReferralReport
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof MemberServiceReportsInternalControllerApiGetMemberReferralReport
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof MemberServiceReportsInternalControllerApiGetMemberReferralReport
     */
    readonly sort?: Array<string>
}

/**
 * MemberServiceReportsInternalControllerApi - object-oriented interface
 * @export
 * @class MemberServiceReportsInternalControllerApi
 * @extends {BaseAPI}
 */
export class MemberServiceReportsInternalControllerApi extends BaseAPI {
    /**
     * 
     * @param {MemberServiceReportsInternalControllerApiGetMemberReferralReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberServiceReportsInternalControllerApi
     */
    public getMemberReferralReport(requestParameters: MemberServiceReportsInternalControllerApiGetMemberReferralReportRequest, options?: RawAxiosRequestConfig) {
        return MemberServiceReportsInternalControllerApiFp(this.configuration).getMemberReferralReport(requestParameters.businessEntityId, requestParameters.referredWhenFrom, requestParameters.referredWhenTo, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }
}

