/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { MemberDto } from '../models';
// @ts-ignore
import type { ProblemDetail } from '../models';
// @ts-ignore
import type { TierProgressDto } from '../models';
/**
 * MembershipControllerApi - axios parameter creator
 * @export
 */
export const MembershipControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembership: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getMembership', 'businessEntityId', businessEntityId)
            const localVarPath = `/me/memberships/{businessEntityId}`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTierProgress1: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('getTierProgress1', 'businessEntityId', businessEntityId)
            const localVarPath = `/me/memberships/{businessEntityId}/tier-progress`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {string} [referralCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        join: async (businessEntityId: string, referralCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('join', 'businessEntityId', businessEntityId)
            const localVarPath = `/me/memberships/{businessEntityId}`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referralCode !== undefined) {
                localVarQueryParameter['referralCode'] = referralCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leave: async (businessEntityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessEntityId' is not null or undefined
            assertParamExists('leave', 'businessEntityId', businessEntityId)
            const localVarPath = `/me/memberships/{businessEntityId}`
                .replace(`{${"businessEntityId"}}`, encodeURIComponent(String(businessEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MembershipControllerApi - functional programming interface
 * @export
 */
export const MembershipControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MembershipControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembership(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembership(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembershipControllerApi.getMembership']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTierProgress1(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TierProgressDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTierProgress1(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembershipControllerApi.getTierProgress1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {string} [referralCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async join(businessEntityId: string, referralCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.join(businessEntityId, referralCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembershipControllerApi.join']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} businessEntityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leave(businessEntityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leave(businessEntityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MembershipControllerApi.leave']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MembershipControllerApi - factory interface
 * @export
 */
export const MembershipControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MembershipControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MembershipControllerApiGetMembershipRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembership(requestParameters: MembershipControllerApiGetMembershipRequest, options?: RawAxiosRequestConfig): AxiosPromise<MemberDto> {
            return localVarFp.getMembership(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MembershipControllerApiGetTierProgress1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTierProgress1(requestParameters: MembershipControllerApiGetTierProgress1Request, options?: RawAxiosRequestConfig): AxiosPromise<TierProgressDto> {
            return localVarFp.getTierProgress1(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MembershipControllerApiJoinRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        join(requestParameters: MembershipControllerApiJoinRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.join(requestParameters.businessEntityId, requestParameters.referralCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MembershipControllerApiLeaveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leave(requestParameters: MembershipControllerApiLeaveRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.leave(requestParameters.businessEntityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMembership operation in MembershipControllerApi.
 * @export
 * @interface MembershipControllerApiGetMembershipRequest
 */
export interface MembershipControllerApiGetMembershipRequest {
    /**
     * 
     * @type {string}
     * @memberof MembershipControllerApiGetMembership
     */
    readonly businessEntityId: string
}

/**
 * Request parameters for getTierProgress1 operation in MembershipControllerApi.
 * @export
 * @interface MembershipControllerApiGetTierProgress1Request
 */
export interface MembershipControllerApiGetTierProgress1Request {
    /**
     * 
     * @type {string}
     * @memberof MembershipControllerApiGetTierProgress1
     */
    readonly businessEntityId: string
}

/**
 * Request parameters for join operation in MembershipControllerApi.
 * @export
 * @interface MembershipControllerApiJoinRequest
 */
export interface MembershipControllerApiJoinRequest {
    /**
     * 
     * @type {string}
     * @memberof MembershipControllerApiJoin
     */
    readonly businessEntityId: string

    /**
     * 
     * @type {string}
     * @memberof MembershipControllerApiJoin
     */
    readonly referralCode?: string
}

/**
 * Request parameters for leave operation in MembershipControllerApi.
 * @export
 * @interface MembershipControllerApiLeaveRequest
 */
export interface MembershipControllerApiLeaveRequest {
    /**
     * 
     * @type {string}
     * @memberof MembershipControllerApiLeave
     */
    readonly businessEntityId: string
}

/**
 * MembershipControllerApi - object-oriented interface
 * @export
 * @class MembershipControllerApi
 * @extends {BaseAPI}
 */
export class MembershipControllerApi extends BaseAPI {
    /**
     * 
     * @param {MembershipControllerApiGetMembershipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipControllerApi
     */
    public getMembership(requestParameters: MembershipControllerApiGetMembershipRequest, options?: RawAxiosRequestConfig) {
        return MembershipControllerApiFp(this.configuration).getMembership(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MembershipControllerApiGetTierProgress1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipControllerApi
     */
    public getTierProgress1(requestParameters: MembershipControllerApiGetTierProgress1Request, options?: RawAxiosRequestConfig) {
        return MembershipControllerApiFp(this.configuration).getTierProgress1(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MembershipControllerApiJoinRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipControllerApi
     */
    public join(requestParameters: MembershipControllerApiJoinRequest, options?: RawAxiosRequestConfig) {
        return MembershipControllerApiFp(this.configuration).join(requestParameters.businessEntityId, requestParameters.referralCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MembershipControllerApiLeaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipControllerApi
     */
    public leave(requestParameters: MembershipControllerApiLeaveRequest, options?: RawAxiosRequestConfig) {
        return MembershipControllerApiFp(this.configuration).leave(requestParameters.businessEntityId, options).then((request) => request(this.axios, this.basePath));
    }
}

