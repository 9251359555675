/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Snapshot
 */
export interface Snapshot {
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    'createdWhen'?: string;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    'updatedWhen'?: string;
    /**
     * 
     * @type {string}
     * @memberof Snapshot
     */
    'snapshotType'?: SnapshotSnapshotTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SnapshotSnapshotTypeEnum {
    BUSINESS_ENTITY = 'BUSINESS_ENTITY',
    BUSINESS_USER = 'BUSINESS_USER',
    MEMBER = 'MEMBER',
    OFFER = 'OFFER',
    TIER_CONFIGURATION = 'TIER_CONFIGURATION',
    REFERRAL_PROGRAM = 'REFERRAL_PROGRAM',
    CONSUMER = 'CONSUMER',
    REWARD = 'REWARD'
}


