/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { BalanceInternalDto } from './balance-internal-dto';

/**
 * 
 * @export
 * @interface ConsumerInternalDto
 */
export interface ConsumerInternalDto {
    /**
     * 
     * @type {string}
     * @memberof ConsumerInternalDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerInternalDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerInternalDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerInternalDto
     */
    'gender'?: ConsumerInternalDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ConsumerInternalDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerInternalDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerInternalDto
     */
    'cognitoEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerInternalDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerInternalDto
     */
    'lastSignIn'?: string;
    /**
     * 
     * @type {BalanceInternalDto}
     * @memberof ConsumerInternalDto
     */
    'balance'?: BalanceInternalDto;
    /**
     * 
     * @type {boolean}
     * @memberof ConsumerInternalDto
     */
    'ghostAccount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConsumerInternalDto
     */
    'requirePhoneVerification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConsumerInternalDto
     */
    'requireEmailVerification'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ConsumerInternalDtoGenderEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER'
}


