/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { PointsConversionRequestDto } from './points-conversion-request-dto';

/**
 * 
 * @export
 * @interface ConsumerDto
 */
export interface ConsumerDto {
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'cognitoEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'gender'?: ConsumerDtoGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConsumerDto
     */
    'ghostAccount'?: boolean;
    /**
     * 
     * @type {Array<PointsConversionRequestDto>}
     * @memberof ConsumerDto
     */
    'conversionRequests'?: Array<PointsConversionRequestDto>;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'pictureKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'pictureUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'thumbnailUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'createdWhen'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'updatedWhen'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDto
     */
    'source'?: ConsumerDtoSourceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ConsumerDtoGenderEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum ConsumerDtoSourceEnum {
    MANUAL_SIGNUP = 'MANUAL_SIGNUP',
    MANUAL = 'MANUAL',
    IMPORT = 'IMPORT',
    ZAPIER = 'ZAPIER',
    LIGHTSPEED = 'LIGHTSPEED',
    MINDBODY = 'MINDBODY'
}


