/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MemberSegmentBreakdownMetricDtoGroup
 */
export interface MemberSegmentBreakdownMetricDtoGroup {
    /**
     * 
     * @type {string}
     * @memberof MemberSegmentBreakdownMetricDtoGroup
     */
    'segment'?: MemberSegmentBreakdownMetricDtoGroupSegmentEnum;
    /**
     * 
     * @type {number}
     * @memberof MemberSegmentBreakdownMetricDtoGroup
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberSegmentBreakdownMetricDtoGroup
     */
    'percentage'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MemberSegmentBreakdownMetricDtoGroupSegmentEnum {
    NEW = 'NEW',
    FIRST_TIME = 'FIRST_TIME',
    LOYAL = 'LOYAL',
    REPEAT = 'REPEAT',
    AT_RISK = 'AT_RISK',
    DORMANT = 'DORMANT'
}


