/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ProblemDetail } from '../models';
/**
 * CampaignControllerApi - axios parameter creator
 * @export
 */
export const CampaignControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetCampaignMembersCount1CampaignChannelEnum} campaignChannel 
         * @param {Array<GetCampaignMembersCount1StatusesEnum>} statuses 
         * @param {Array<GetCampaignMembersCount1GendersEnum>} [genders] 
         * @param {Array<string>} [tierLevels] 
         * @param {Array<GetCampaignMembersCount1SegmentsEnum>} [segments] 
         * @param {number} [ageFrom] 
         * @param {number} [ageTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignMembersCount1: async (campaignChannel: GetCampaignMembersCount1CampaignChannelEnum, statuses: Array<GetCampaignMembersCount1StatusesEnum>, genders?: Array<GetCampaignMembersCount1GendersEnum>, tierLevels?: Array<string>, segments?: Array<GetCampaignMembersCount1SegmentsEnum>, ageFrom?: number, ageTo?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignChannel' is not null or undefined
            assertParamExists('getCampaignMembersCount1', 'campaignChannel', campaignChannel)
            // verify required parameter 'statuses' is not null or undefined
            assertParamExists('getCampaignMembersCount1', 'statuses', statuses)
            const localVarPath = `/campaigns/members/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignChannel !== undefined) {
                localVarQueryParameter['campaignChannel'] = campaignChannel;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = Array.from(statuses);
            }

            if (genders) {
                localVarQueryParameter['genders'] = Array.from(genders);
            }

            if (tierLevels) {
                localVarQueryParameter['tierLevels'] = Array.from(tierLevels);
            }

            if (segments) {
                localVarQueryParameter['segments'] = Array.from(segments);
            }

            if (ageFrom !== undefined) {
                localVarQueryParameter['ageFrom'] = ageFrom;
            }

            if (ageTo !== undefined) {
                localVarQueryParameter['ageTo'] = ageTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignControllerApi - functional programming interface
 * @export
 */
export const CampaignControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetCampaignMembersCount1CampaignChannelEnum} campaignChannel 
         * @param {Array<GetCampaignMembersCount1StatusesEnum>} statuses 
         * @param {Array<GetCampaignMembersCount1GendersEnum>} [genders] 
         * @param {Array<string>} [tierLevels] 
         * @param {Array<GetCampaignMembersCount1SegmentsEnum>} [segments] 
         * @param {number} [ageFrom] 
         * @param {number} [ageTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignMembersCount1(campaignChannel: GetCampaignMembersCount1CampaignChannelEnum, statuses: Array<GetCampaignMembersCount1StatusesEnum>, genders?: Array<GetCampaignMembersCount1GendersEnum>, tierLevels?: Array<string>, segments?: Array<GetCampaignMembersCount1SegmentsEnum>, ageFrom?: number, ageTo?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignMembersCount1(campaignChannel, statuses, genders, tierLevels, segments, ageFrom, ageTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignControllerApi.getCampaignMembersCount1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CampaignControllerApi - factory interface
 * @export
 */
export const CampaignControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CampaignControllerApiGetCampaignMembersCount1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignMembersCount1(requestParameters: CampaignControllerApiGetCampaignMembersCount1Request, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.getCampaignMembersCount1(requestParameters.campaignChannel, requestParameters.statuses, requestParameters.genders, requestParameters.tierLevels, requestParameters.segments, requestParameters.ageFrom, requestParameters.ageTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getCampaignMembersCount1 operation in CampaignControllerApi.
 * @export
 * @interface CampaignControllerApiGetCampaignMembersCount1Request
 */
export interface CampaignControllerApiGetCampaignMembersCount1Request {
    /**
     * 
     * @type {'SMS' | 'EMAIL'}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly campaignChannel: GetCampaignMembersCount1CampaignChannelEnum

    /**
     * 
     * @type {Array<'MEMBER' | 'GUEST' | 'BLOCKED'>}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly statuses: Array<GetCampaignMembersCount1StatusesEnum>

    /**
     * 
     * @type {Array<'MALE' | 'FEMALE' | 'OTHER'>}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly genders?: Array<GetCampaignMembersCount1GendersEnum>

    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly tierLevels?: Array<string>

    /**
     * 
     * @type {Array<'NEW' | 'FIRST_TIME' | 'LOYAL' | 'REPEAT' | 'AT_RISK' | 'DORMANT'>}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly segments?: Array<GetCampaignMembersCount1SegmentsEnum>

    /**
     * 
     * @type {number}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly ageFrom?: number

    /**
     * 
     * @type {number}
     * @memberof CampaignControllerApiGetCampaignMembersCount1
     */
    readonly ageTo?: number
}

/**
 * CampaignControllerApi - object-oriented interface
 * @export
 * @class CampaignControllerApi
 * @extends {BaseAPI}
 */
export class CampaignControllerApi extends BaseAPI {
    /**
     * 
     * @param {CampaignControllerApiGetCampaignMembersCount1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignControllerApi
     */
    public getCampaignMembersCount1(requestParameters: CampaignControllerApiGetCampaignMembersCount1Request, options?: RawAxiosRequestConfig) {
        return CampaignControllerApiFp(this.configuration).getCampaignMembersCount1(requestParameters.campaignChannel, requestParameters.statuses, requestParameters.genders, requestParameters.tierLevels, requestParameters.segments, requestParameters.ageFrom, requestParameters.ageTo, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum GetCampaignMembersCount1CampaignChannelEnum {
    SMS = 'SMS',
    EMAIL = 'EMAIL'
}
/**
  * @export
  * @enum {string}
  */
export enum GetCampaignMembersCount1StatusesEnum {
    MEMBER = 'MEMBER',
    GUEST = 'GUEST',
    BLOCKED = 'BLOCKED'
}
/**
  * @export
  * @enum {string}
  */
export enum GetCampaignMembersCount1GendersEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER'
}
/**
  * @export
  * @enum {string}
  */
export enum GetCampaignMembersCount1SegmentsEnum {
    NEW = 'NEW',
    FIRST_TIME = 'FIRST_TIME',
    LOYAL = 'LOYAL',
    REPEAT = 'REPEAT',
    AT_RISK = 'AT_RISK',
    DORMANT = 'DORMANT'
}
