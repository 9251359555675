/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ConsumerDtoDefaultView
 */
export interface ConsumerDtoDefaultView {
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'gender'?: ConsumerDtoDefaultViewGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConsumerDtoDefaultView
     */
    'ghostAccount'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'pictureKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'pictureUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'thumbnailUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'createdWhen'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'updatedWhen'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerDtoDefaultView
     */
    'source'?: ConsumerDtoDefaultViewSourceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ConsumerDtoDefaultViewGenderEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum ConsumerDtoDefaultViewSourceEnum {
    MANUAL_SIGNUP = 'MANUAL_SIGNUP',
    MANUAL = 'MANUAL',
    IMPORT = 'IMPORT',
    ZAPIER = 'ZAPIER',
    LIGHTSPEED = 'LIGHTSPEED',
    MINDBODY = 'MINDBODY'
}


